import { PolicyHolder } from '@interfaces/policyholder';
import { MockPolicyHolder } from '../data/policy-holder';

export const FakePolicyHolderKey = 'mock-policyholder';
const defaultPolicyHolder = JSON.stringify(MockPolicyHolder);

export function ClearFakePolicyHolder() {
  SetFakePolicyHolder(undefined);
}

export function SetFakePolicyHolder(policyHolder: PolicyHolder) {
  if (policyHolder === undefined) {
    delete window.localStorage[FakePolicyHolderKey];
    return;
  }

  window.localStorage[FakePolicyHolderKey] = JSON.stringify(policyHolder);
}

export function GetFakePolicyHolder() {
  return JSON.parse(
    window.localStorage[FakePolicyHolderKey] || defaultPolicyHolder
  ) as PolicyHolder;
}
