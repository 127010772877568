import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { createEffect, ofType, Actions, concatLatestFrom } from "@ngrx/effects";
import { RenewalActions } from "../actions/renewal.actions";
import { filter, map, tap, switchMap, catchError } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { PolicySelectors } from "@features/policies/store/policy.selectors";
import { CoverSelectionSelectors } from "../selectors/cover-selection.selector";
import { reviewCoverActions } from "../actions/review-cover.actions";
import { selectIsFeatureEnabled } from "@shared/feature-flags/store/feature-flag.selector";
import { PolicyService } from "@features/policies/services/policy.service";
import { FeatureFlags } from "@shared/enums/feature-flags.enum";
import { PolicyMethods } from "@features/policies/services/policy-methods.service";
import { of } from "rxjs";
import {
  RenewalChangeSummary, 
} from "@features/policies/interfaces/renewal-change-summary.interface";
import { Renewal } from "@features/policies/interfaces/renewal.interface";
import { 
  ClientDetails, 
} from "@features/communication-preferences/interfaces/client-details.interface";
import { policyHolderActions } from "@features/account-details/store/actions/policy-holder.actions";
import { ClientService } from "@features/account-details/services/client.service";
import { policyActions } from "@features/policies/store/policy.actions";
import { 
  PolicyPremiumRecalculationService,
} from "@features/policies/services/policy-premium-recalculation.service";
import { RenewalSelectors } from "../selectors/renewal.selectors";
import { CancellationService } from "@features/policies/services/cancellation.service";

@Injectable()
export class RenewalEffects {
  policySummaryRedirect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          RenewalActions.dismissRenewalJourney,
          RenewalActions.completeRenewalJourney,
        ),
        tap(() =>
          this.router.navigate([
            '/logged-in',
            'policies',
            'policy-summary',
          ]),
        ),
      );
    },
    { dispatch: false },
  );

  renewalPaymentSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          RenewalActions.paymentSuccess,
        ),
        concatLatestFrom(() => [
          this.store.select(PolicySelectors.selectPolicy),
          this.store.select(CoverSelectionSelectors.selectChangeCoverQuote),
          this.store.select(RenewalSelectors.selectDiscountAccepted),
        ]),
        map(([_, policy, quote, discountAccepted]) => {
          if (discountAccepted === true) {
            return RenewalActions.applyRenewalDiscount();
          }
          if (policy.excess === quote.excess && policy.productId === quote.productId) {
            return RenewalActions.acceptRenewal();
          } else {
            return reviewCoverActions.updateCover();
          }
        }),
      );
    },
  );

  acceptRenewal$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        RenewalActions.acceptRenewal,
      ),
      concatLatestFrom(() => [
        this.store.select(PolicySelectors.selectPolicy),
      ]),
      filter(
        ([_, policy]) =>
          !this.policyMethods.isMidTerm(policy) 
          && !this.policyMethods.isNewBusiness(policy),
      ),
      switchMap(
        ([_, policy]) => 
          this.policyService.patchPolicyRenewal(policy.policyUniqueRef, { accepted: true }).pipe(
            map(() => reviewCoverActions.updateCoverSuccess()),
            catchError(() => of(RenewalActions.acceptRenewalFailure())),
          ),
      ),
    );
  });
  
  fetchRenewalInformation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RenewalActions.importantInfoPageLoaded),
      concatLatestFrom(() =>
        this.store.select(PolicySelectors.selectPolicy),
      ),
      switchMap(([_, policy]) =>
        this.policyService
          .getRenewalSummary(policy.policyUniqueRef)
          .pipe(
            map((summary: RenewalChangeSummary) =>
              RenewalActions.fetchImportantInfoSuccess({
                changes: summary.changes,
              }),
            ),
            catchError(() => of(RenewalActions.fetchImportantInfoFailure())),
          ),
      ));
  });

  startRenewalJourney$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RenewalActions.startRenewalJourney),
      concatLatestFrom(() => this.store.select(PolicySelectors.selectPolicy)),
      switchMap(([_, policy]) =>
        this.policyService.getRenewal(policy.policyUniqueRef).pipe(
          map((renewal: Renewal) => 
            RenewalActions.fetchRenewalSuccess(
              {
                isConfirmationRequired : renewal.isConfirmationRequired,
              }),
          ),
          catchError(() => of(RenewalActions.fetchRenewalFailure())),
        ),
      ));
  });
  

  fetchPersonalDetails$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RenewalActions.benefitsPageLoaded),
      concatLatestFrom(() => this.store.select(selectIsFeatureEnabled(FeatureFlags.LaunchJoii))),
      filter(([_,isJoiiFeatureEnabled]) => isJoiiFeatureEnabled === true),
      switchMap(() =>
        this.clientService.getPersonalDetails().pipe(
          map((personalDetails: ClientDetails) => 
            policyHolderActions.fetchPersonalDetailsSuccess(
              {
                isJoiiRegistered : personalDetails.isJoiiRegistered,
              },
            )),
          catchError(() => of(policyHolderActions.fetchPersonalDetailsFailure())),
        ),
      ),
    );
  });

  resetCancelFormState$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RenewalActions.cancelPageLoaded),
      concatLatestFrom(() =>
        this.store.select(selectIsFeatureEnabled(FeatureFlags.CancellationDiscounts)),
      ),
      filter(([_, discountFeatureEnabled]) => !discountFeatureEnabled),
      map(() => policyActions.cancelPolicyReset()),
    );
  });
  
  fetchCancellationReasons$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RenewalActions.cancelPageLoaded),
      concatLatestFrom(() =>
        this.store.select(PolicySelectors.selectPolicy),
      ),
      switchMap(([_, policy]) => 
        this.cancellationService.getCancellationReasons(policy.pet?.type).pipe(
          map((cancellationReasons) => RenewalActions.fetchCancellationReasonsSuccess(
            { cancellationReasons },
          )),
          catchError(() => of(RenewalActions.fetchCancellationReasonsFailure())),
        ),
      ),
    );
  });
  
  fetchCancellationProviders$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RenewalActions.cancelPageLoaded),
      switchMap(() => 
        this.cancellationService.getInsuranceProviders().pipe(
          map((cancellationProviders) => RenewalActions.fetchCancellationProvidersSuccess(
            { cancellationProviders },
          )),
          catchError(() => of(RenewalActions.fetchCancellationProvidersFailure())),
        ),
      ),
    );
  });

  fetchDiscount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        RenewalActions.cancelPageLoaded,
        RenewalActions.discountsPageLoaded,
      ),
      concatLatestFrom(() => [
        this.store.select(PolicySelectors.selectPolicy),
        this.store.select(selectIsFeatureEnabled(FeatureFlags.CancellationDiscounts)),
      ]),
      filter(([_, _policy, discountFeatureEnabled]) => discountFeatureEnabled),
      switchMap(([_, policy]) =>
        this.policyService.getDiscount(policy.policyUniqueRef).pipe(
          map((discount) => RenewalActions.fetchDiscountSuccess({ discount })),
        ),
      ),
    );
  });

  fetchDiscountPrice$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        RenewalActions.fetchDiscountSuccess,
      ),
      filter((discount) => discount.discount.factor !== 1),
      concatLatestFrom(() => this.store.select(PolicySelectors.selectPolicy)),
      switchMap(([discountResponse, policy]) =>
        this.policyPremiumRecalculationService.getPolicyPremiumRecalculation(
          policy.policyUniqueRef, 
          { transientAdjustment: discountResponse.discount.factor },
        ).pipe(
          map((discountedPremiumRecalculation) => RenewalActions.fetchDiscountPriceSuccess(
            { 
              premiumRecalculation: discountedPremiumRecalculation, 
            },
          )),
        ),
      ),
    );
  });

  applyRenewalDiscount$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          RenewalActions.applyRenewalDiscount,
        ),
        concatLatestFrom(() => [
          this.store.select(PolicySelectors.selectPolicy),
        ]),
        switchMap(([_, policy]) => this.policyService.applyDiscount(policy.policyUniqueRef)
          .pipe(
            map((response) => {
              if(response.createdId !== 0) {
                return RenewalActions.applyRenewalDiscountSuccess();
              } else {
                return RenewalActions.applyRenewalDiscountFailure();
              }
            }),
            catchError(() => of(RenewalActions.applyRenewalDiscountFailure()))),
        ));
    },
  );

  errorPageRedirect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          RenewalActions.applyRenewalDiscountFailure,
        ),
        tap(() =>
          this.router.navigate([
            '/error',
          ]),
        ),
      );
    },
    { dispatch: false },
  );
  
  benefitsPageRedirect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          RenewalActions.applyRenewalDiscountSuccess,
        ),
        concatLatestFrom(() => [
          this.store.select(PolicySelectors.selectPolicy),
        ]),
        tap(([_, policy]) =>
          this.router.navigate([
            '/logged-in',
            'renewal',
            policy.policyUniqueRef,
            'benefits',
          ]),
        ),
      );
    },
    { dispatch: false },
  );
  
  constructor(
    private actions$: Actions,
    private router: Router,
    private store: Store,
    private policyService: PolicyService,
    private policyMethods: PolicyMethods,
    private clientService: ClientService,
    private policyPremiumRecalculationService: PolicyPremiumRecalculationService,
    private cancellationService: CancellationService,
  ) {}
}
