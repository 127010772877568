<div>
  <p translate>
    AUTH.POLICY.CHANGEOFCOVER.COPAYMENTSEXPLANATION.EXCESS
  </p>
  <p *ngIf="getPetAge() >= getcoPaymentAge()">{{'AUTH.POLICY.CHANGEOFCOVER.IMPORTANTINFORMATION.COPAYMENTSMOREINFO.OVERCOPAYMENTAGE'  | translate: { petType: policy?.pet.type, copaymentAge: getcoPaymentAge(policy) } }}</p>
  <p *ngIf="getPetAge() < getcoPaymentAge()">{{'AUTH.POLICY.CHANGEOFCOVER.IMPORTANTINFORMATION.COPAYMENTSMOREINFO.UNDERCOPAYMENTAGE'  | translate: { petType: policy?.pet.type, copaymentAge: getcoPaymentAge(policy) } }}</p>
  <div class="mb-3" translate>
    AUTH.POLICY.CHANGEOFCOVER.IMPORTANTINFORMATION.COPAYMENTSMOREINFO.TEXT
  </div>
  <app-copayments-example-table>
  </app-copayments-example-table>
</div>