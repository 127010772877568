import { Pet } from '@interfaces/pet';

export const MockBumblePet: Pet = {
  petName: 'Bumble',
  petBreed: 'Moggie',
  petDOB: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 365 * 3),
  petColour: 'Red',
  petGender: 'Male',
  microchipNo: null,
  heightHands: null,
  heightInches: null,
  riderDOB: null,
  riderFirstName: null,
  riderSurname: null,
  type: 'cat',
  horseActivity: null,
  horsePostcode: null,
};

export const MockLillyPet: Pet = {
  petName: 'Lilly',
  petBreed: 'St Bernard',
  petDOB: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 365 * 10),
  petColour: 'White',
  petGender: 'Female',
  microchipNo: null,
  heightHands: null,
  heightInches: null,
  riderDOB: null,
  riderFirstName: null,
  riderSurname: null,
  type: 'dog',
  horseActivity: null,
  horsePostcode: null,
};

export const MockSharronPet: Pet = {
  petName: 'Sharron',
  petBreed: 'Moggie',
  petDOB: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 365 * 6),
  petColour: 'Red',
  petGender: 'Female',
  microchipNo: null,
  heightHands: null,
  heightInches: null,
  riderDOB: null,
  riderFirstName: null,
  riderSurname: null,
  type: 'cat',
  horseActivity: null,
  horsePostcode: null,
};

export const MockTerraklausPet: Pet = {
  petName: 'Terraklaus',
  petBreed: 'Siberian',
  petDOB: new Date(2017, 2, 10),
  petColour: 'Tabby',
  petGender: 'Female',
  microchipNo: null,
  heightHands: null,
  heightInches: null,
  riderDOB: null,
  riderFirstName: null,
  riderSurname: null,
  type: 'cat',
  horseActivity: null,
  horsePostcode: null,
};

export const MockMollyPet: Pet = {
  petName: 'Molly',
  petBreed: 'Moggie',
  petDOB: new Date(2016, 1, 1),
  petColour: 'Grey',
  petGender: 'Female',
  microchipNo: null,
  heightHands: null,
  heightInches: null,
  riderDOB: null,
  riderFirstName: null,
  riderSurname: null,
  type: 'cat',
  horseActivity: null,
  horsePostcode: null,
};

export const MockHalifaxPet: Pet = {
  petName: 'Halifax',
  petBreed: 'Dutch Warmblood',
  petDOB: new Date(2012, 4, 12),
  petColour: 'Bay',
  petGender: 'Female',
  microchipNo: null,
  heightHands: 17,
  heightInches: 1,
  riderDOB: null,
  riderFirstName: null,
  riderSurname: null,
  type: 'horse',
  horseActivity: null,
  horsePostcode: 'SP11 0HY',
};

export const MockPeterSpladgeRider: Pet = {
  petName: null,
  petBreed: null,
  petDOB: null,
  petColour: null,
  petGender: null,
  microchipNo: null,
  heightHands: null,
  heightInches: null,
  riderDOB: new Date(2000, 1, 10),
  riderFirstName: 'Peter',
  riderSurname: 'Spladge',
  type: 'Rider Only',
  horseActivity: null,
  horsePostcode: 'SP11 0HY',
};

export const MockPetMap = {
  MockHalifaxPet,
  MockMollyPet,
  MockTerraklausPet,
  MockSharronPet,
  MockLillyPet,
  MockBumblePet,
  MockPeterSpladgeRider,
};

export const MockPets: Pet[] = Object.keys(MockPetMap).map(
  (e) => MockPetMap[e]
);
