import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, concatLatestFrom } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ClientService } from '@features/account-details/services/client.service';
import { Store } from '@ngrx/store';
import { policyHolderActions } from '@features/account-details/store/actions/policy-holder.actions';
import {
  communicationPreferencesSelectors,
} from '../selectors/communication-preferences.selectors';
import { communicationPreferencesActions } from '../actions/communication-preferences.actions';

@Injectable()
export class CommunicationPreferencesEffects {
  fetchCommunicationPreferences$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        communicationPreferencesActions.fetchCommunicationPreferences,
        policyHolderActions.accountDetailsPageLoaded,
      ),
      switchMap(() =>
        this.clientService.getCommunicationPreferences().pipe(
          map((communicationPreferences) =>
            communicationPreferencesActions.fetchCommunicationPreferencesSuccess(
              { communicationPreferences },
            ),
          ),
          catchError(() =>
            of(
              communicationPreferencesActions.fetchCommunicationPreferencesFailure(),
            ),
          ),
        ),
      ),
    );
  });

  updateCommunicationPreferences$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(communicationPreferencesActions.updateCommunicationPreferences),
      concatLatestFrom(() =>
        this.store.select(
          communicationPreferencesSelectors.selectCommunicationPreferences,
        ),
      ),
      switchMap(([_, communicationPreferences]) =>
        this.clientService
          .updateCommunicationPreferences(communicationPreferences)
          .pipe(
            map(() =>
              communicationPreferencesActions.updateCommunicationPreferencesSuccess(),
            ),
            catchError((error: unknown) => {
              if (typeof error === 'string') {
                return of(
                  communicationPreferencesActions.updateCommunicationPreferencesError(
                    { error },
                  ),
                );
              }

              throw error;
            }),
          ),
      ),
    );
  });

  updateCommunicationPreferencesError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        communicationPreferencesActions.updateCommunicationPreferencesError,
      ),
      map(() => communicationPreferencesActions.cancelChanges()),
    );
  });

  constructor(
    private clientService: ClientService,
    private actions$: Actions,
    private store: Store,
  ) {}
}
