import { StaticDocument } from '@interfaces/static-document';

const MockStaticDocumentTermsOfBusiness = {
  staticDocumentId: 110,
  friendlyName: 'Terms of Business V.5 FINAL.pdf',
  staticDocumentTypeId: 4,
};

const MockStaticDocumentIPID = {
  staticDocumentId: 151,
  friendlyName: 'Insurance Product Information Document   Prestige.pdf',
  staticDocumentTypeId: 5,
};

const MockStaticDocumentPolicyWording = {
  staticDocumentId: 165,
  friendlyName: 'Prestige Policy Wording.V3.Small.pdf',
  staticDocumentTypeId: 3,
};

const MockStaticDocumentKnowYourCover = {
  staticDocumentId: 170,
  friendlyName: 'KnowYourCover_Jan2020.pdf',
  staticDocumentTypeId: 3,
};

export const MockDocumentMap = {
  MockStaticDocumentTermsOfBusiness,
  MockStaticDocumentIPID,
  MockStaticDocumentPolicyWording,
  MockStaticDocumentKnowYourCover,
};
export const MockAllStaticDocuments: StaticDocument[] = Object.keys(
  MockDocumentMap
).map((e) => MockDocumentMap[e]);
