export enum HorseColours{
    Appaloosa= "Appaloosa",
    Bay= "Bay",
    BayRoan = "Bay Roan",
    Black= "Black",
    BlanketSpot = "Blanket Spot",
    BlueRoan = "Blue Roan",
    BrightBay = "Bright Bay",
    Brown = "Brown",
    Buckskin = "Buckskin",
    Champagne = "Champagne",
    Chestnut = "Chestnut",
    ChocolateDun = "Chocolate Dun",
    Cremello = "Cremello",
    DappleGrey ="Dapple Grey",
    DarkBay = "Dark Bay",
    Dun = "Dun",
    FleaBittenGrey= "Flea Bitten Grey",
    Grey = "Grey",
    GreyRoan = "Grey Roan",
    IronGrey = "Iron Grey",
    LeopardSpot = "Leopard Spot",
    LiverChestnut = "Liver Chestnut",
    Palomino = "Palomino",
    Piebald = "Piebald",
    RedDun = "Red Dun",
    Roan = "Roan",
    RoseGrey = "Rose Grey",
    Skewbald = "Skewbald",
    Spotted = "Spotted",
    Sorrel = "Sorrel",
    SteelGrey = "Steel Grey",
    StrawberryRoan = "Strawberry Roan",
    Tobian = "Tobian",
    TriColoured = "Tri Coloured",
}