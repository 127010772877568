import { PolicyPaymentRequestDto } from '@features/policies/interfaces/policy-payment-request.interface';
import { PolicyPayments } from '@interfaces/policy-payments';

const NoPayments: PolicyPayments = {
  hasGivenCPAConsent: false,
  annualCustomerCanMakePayment: false,
  monthlyCustomerCanMakeMissedPayment: false,
  finalMissedPaymentDate: null,
  hasValidAddress: true,
};

const AnnualCustomerCanMakePayment: PolicyPayments = {
  hasGivenCPAConsent: false,
  annualCustomerCanMakePayment: true,
  monthlyCustomerCanMakeMissedPayment: false,
  finalMissedPaymentDate: null,
  hasValidAddress: true,
};

const CanMakeMissedPayment: PolicyPayments = {
  hasGivenCPAConsent: false,
  annualCustomerCanMakePayment: false,
  monthlyCustomerCanMakeMissedPayment: true,
  finalMissedPaymentDate: new Date(),
  hasValidAddress: true,
};

const AnnualPayerInvalidAddress: PolicyPayments = {
  hasGivenCPAConsent: false,
  annualCustomerCanMakePayment: true,
  monthlyCustomerCanMakeMissedPayment: false,
  finalMissedPaymentDate: null,
  hasValidAddress: false
}

export const MockPolicyPaymentsMap = {
  NoPayments,
  AnnualCustomerCanMakePayment,
  CanMakeMissedPayment,
  AnnualPayerInvalidAddress
};

export const MockPolicyPayments: PolicyPayments[] = Object.keys(
  MockPolicyPaymentsMap
).map((e) => MockPolicyPaymentsMap[e]);

export const MockPolicyPaymentRequestDto: PolicyPaymentRequestDto = {
  policyUniqueRef: "ABC",
  amount: 400,
  cpaConsent: true,
  paymentType: "Annual",
  expectedPaymentUniqueId: "DEF",
}
