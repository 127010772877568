import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogoutModalComponent } from './logout-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';



@NgModule({
  declarations: [
    LogoutModalComponent,
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    NgbModalModule,
    TranslateModule,
  ],
  exports: [
    LogoutModalComponent,
  ],
})
export class LogoutModalModule { }
