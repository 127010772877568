import { PolicyCondition } from '@interfaces/policy-condition';

export const MockAbdominalPainCondition: PolicyCondition = {
  claimID: 1,
  claimSubID: 1,
  condition: 'Abdominal Pain',
};

export const MockBlindnessCondition: PolicyCondition = {
  claimID: 2,
  claimSubID: 2,
  condition: 'Blindness',
};

export const MockCanineCognitiveDysfunctionSyndromeCondition: PolicyCondition = {
  claimID: 3,
  claimSubID: 3,
  condition: 'Canine Cognitive Dysfunction Syndrome',
};

export const MockDeafnessCondition: PolicyCondition = {
  claimID: 4,
  claimSubID: 4,
  condition: 'Deafness',
};

export const MockEpilepsyCondition: PolicyCondition = {
  claimID: 5,
  claimSubID: 5,
  condition: 'Epilepsy',
};

export const MockInactiveCondition: PolicyCondition = {
  claimID: 6,
  claimSubID: 6,
  condition: 'Inactive previous claim condition',
};

export const MockPolicyConditionsMap = {
  MockAbdominalPainCondition,
  MockBlindnessCondition,
  MockCanineCognitiveDysfunctionSyndromeCondition,
  MockDeafnessCondition,
  MockEpilepsyCondition,
  MockInactiveCondition,
};

export const MockPolicyConditions: PolicyCondition[] = Object.values(
  MockPolicyConditionsMap
);
